import Vue from 'vue';

const AutoBlur = {
  inserted: function (el) {
    el.onfocus = (ev) => ev.target.blur()
  }
};
export default {
  AutoBlur,
};

Vue.directive('blur', AutoBlur);
