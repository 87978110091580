export default  {
  methods: {
    doNoticeSuccess (msg) {
      this.$root.notice = { type: 'success', msg: msg }
    },
    doNoticeError (msg) {
      this.$root.notice = { type: 'error', msg: msg }
    },
    doNoticeWarning (msg) {
      this.$root.notice = { type: 'warning', msg: msg }
    },
    doAppError (msg = '') {
      this.$root.appError = {
        show: true,
        msg: msg
      }
    }
  }
}
