import Vue from 'vue'
import VueI18n from 'vue-i18n'


Vue.use(VueI18n)
export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  numberFormats: {
    'en-EN': {
      currency: {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol'
      }
    },
    'de-DE': {
      currency: {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol'
      }
    }
  }
})



