<template>
  <v-app id="app">
    <router-view v-if="computedIsInited" @notice="notice"></router-view>
    <dialog-loading v-if="!isInited" :dialog="true"/>
    <dialog-app-error :dialog-app-error="dialogAppError" />
    <v-snackbar  v-model="snack" :timeout="snackTimeout" :color="snackColor">
      <div class="d-flex justify-space-between">
        {{ snackText }}
        <v-icon small dark class="ml-2" @click="snack = false">mdi-close</v-icon>
      </div>
    </v-snackbar>
  </v-app>
</template>

<script>
import DialogAppError from '@/_shared/components/DialogAppError'
import DialogLoading from '@/_shared/components/DialogLoading'


export default {
  name: 'App',
  components: { DialogLoading,  DialogAppError },
  data () {
    return {
      snack: false,
      snackColor: '',
      snackText: '',
      snackTimeout: 5000,
      dialogAppError: false,
      isInited: false
    }
  },
  computed: {
    appError () {
      return this.$root.appError
    },
    notice() {
      return this.$root.notice;
    },
    computedIsInited () {
      return this.isInited
    }
  },
  watch: {
    notice(n) {
      if(n.type === 'error') {
        this.snackTimeout = 0
      } else {
        this.snackTimeout = 5000
      }
      this.snackColor = n.type
      this.snack = true
      this.snackText = n.msg
    },
    'appError.show'(n) {
      this.dialogAppError = n
    }
  },
  mounted () {
    this.$http.init(this)
    this.$utils.init(this)
    this.init()
  },
  methods: {
    init () {
      this.$http.etc.get('get_token', {params: {app: 'anfrage'}})
      .then(result => {
        result = this.$http.filterApiCollection(result.data.result)
        sessionStorage.setItem('token', result.token)
        this.$store.commit('setConfig', result.config)
        this.$store.commit('setToken', result.token)
        this.initApp()
      })
      .catch(()=> {
        sessionStorage.clear()
        window.location= process.env.VUE_APP_URL
      })
    },
    initApp() {
      this.$http.etc.get('app_init', { params: { app: 'anfrage' } })
      .then(result => {
        result = this.$http.filterApiCollection(result.data.result)
        result.instance.countries = result.instance.countries_iso.split('|')
        this.$store.commit('setToken', result.token)
        this.$store.commit('setConfig', result.config)
        this.$store.commit('setInstance', result.instance)
        this.$store.dispatch('getSessionDataFromLocaleStorage')
        this.setStyle(result)
        this.getGoogleScript()
        this.getInstanceAddons()

      })
      .catch(()=> {
        sessionStorage.clear()
        window.location= process.env.VUE_APP_URL
      })
    },
    getInstanceAddons() {
      if(!this.$store.getters.addons) {
        this.$http.api.get('vw_instance_addons', {params: {id_instance: this.$store.getters.instance.id}} )
        .then(result => {
          result = this.$http.filterApiCollection(result)
          this.$store.commit('setAddons', result)
          this.isInited = true
          this.setLang()
        })
      } else {
        this.isInited = true
        this.setLang()
      }
    },
    getGoogleScript() {
      this.$http.ext.get('get_google_maps_script_src').then(result => {
        this.pMap = new Promise((resolve) => {
          window['GoogleMapsInit'] = resolve
          let GMap = document.createElement('script')
          GMap.setAttribute('src',  result.data.result + '&callback=GoogleMapsInit&region=DE&libraries=visualization')
          document.head.appendChild(GMap)
        })
        if (this.pMap) {
          this.pMap.then(() => {
            this.$root.googleMapsIsLoaded = true
          })
        }
      })
    },
    setStyle(result) {
      let style = this.$_.isObject(JSON.parse(result.instance.style)) ? JSON.parse(result.instance.style) : Object.assign({}, result.config.defaultStyle)
      this.$utils.setStyle(style, this.$vuetify)
    },
    setLang() {
      /* I18 */
      if(!this.$store.getters.langs) {
        this.$http.etc.get('get_lang').then(result => {
          result = result.data.result
          result.map(row => {
            row.data = JSON.parse(row.data)
            row.id = parseInt(row.id)
          })
          this.$store.commit('setLangs', result)
          this.setI18Data()
        })
      } else {
        this.setI18Data()
      }
    },
    setI18Data() {
      this.$store.getters.langs.map(row => {
        if(row.lang == 'en') {
          const ad = row.data.addons
          let a = this.$store.getters.addons

          this.$_.forEach(a, item => {
            const n = this.$_.snakeCase(item.name)
            if(ad[n]) {
              item.name_en = ad[n]
            }
          })
          this.$store.commit('setAddons', a)
        }

        this.$i18n.setLocaleMessage(row.lang, row.data)
        if(sessionStorage.getItem('locale')) {
          this.$i18n.locale = sessionStorage.getItem('locale')
        }
      })
    },
  }
}
</script>

