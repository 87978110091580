import moment from 'moment'

let utilsPlugin = {}

utilsPlugin.install = (Vue) => {
  let vue = Vue
  let config = {}
  const utils = {
    init: (v, c = {}) => {
      vue = v
      config = c
    },
    binMap: [
      '0000',
      '1000',
      '0100',
      '1100',
      '0010',
      '1010',
      '0110',
      '1110',
      '0001',
      '1001',
      '0101',
      '1101',
      '0011',
      '1011',
      '0111',
      '1111'
    ],
    round: {
      roundDistance: (v) => {
        let f = 0
        vue.$_.forEach(vue.$root.config.distanceSteps, item => {
          if (item >= v && !f) {
            f = item
          }
        })
        return f
      },
      roundLoadingMeters: (v) => {
        let f = 0
        vue.$_.forEach(vue.$root.config.loadingMetersSteps, item => {
          if (item >= v && !f) {
            f = item
          }
        })
        return f == 0 ? v : f
      }
    },

    format: {
      toFloat: (v) => {
        if (!v || vue.$_.isNaN(v)) {
          return 0
        }
        return v.toLocaleString('en-EN', { maximumSignificantDigits: 2 })
      },

      germanFloatToFloat: (v) => {
        if (!v) {
          v = 0
        }
        const vs = v.toString()
        v = vs.replace(/\.+/g, '')
        if (v.indexOf(',') > -1) {
          v = v.replace(/,+/g, '.')
        }
        return parseFloat(v)
      },

      toGermanFloat: (v, d = 2) => {
        if (!v || vue.$_.isNaN(parseFloat(v))) {
          return parseFloat(0).toFixed(d).replace(/\.+/g, ',')
        }
        v = v.toString()
        return parseFloat(v.replace(/,+/g, '.')).toFixed(d).replace(/\.+/g, ',')
      },

      toGermanPriceFloat: (v, d = 2, withThousandsSeperator = true) => {
        const numberFormat = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: d, maximumFractionDigits: d, useGrouping: withThousandsSeperator })
        return numberFormat.format(v)
      },

      toGermanDate: (v) => {
        moment.locale('de')
        return moment(v).format('DD.MM.YYYY')
      },

      toGermanDateTime: (v) => {
        moment.locale('de')
        return moment(v).format('DD.MM.YYYY HH:mm:ss')
      },

      intToBin: (v) => {
        return utils.binMap[v]
      },

      binToInt: (v) => {
        return utils.binMap.indexOf(v)
      },

      dayIntToGermanDay: (v, long) => {
        const map = !long ? ['MO', 'DI', 'MI', 'DO', 'FR', 'SA', 'SO'] : ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
        return map[v - 1]
      }
    },

    getHours: () => {
      return vue.$_.range(0, 24).map(i => {
        return vue.$_.padStart(i.toString(), 2, '0') + ':00'
      })
    },

    getHoursAsObject: () => {
      return vue.$_.range(0, 24).map(i => {
        return {
          text: vue.$_.padStart(i.toString(), 2, '0') + ':00',
          value: i
        }
      })
    },

    calculateNeededLoadingmeters: (weightInKilo, length, lm_tonnage) => {
      let t = parseFloat(weightInKilo / 1000)
      return utils.round.roundLoadingMeters(Math.max(lm_tonnage * t, length))
    },

    scrollToTop: () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },

    translate: (txt,  multiple, isSnakeCase, prefix = 'main') => {
      if(multiple) {
        return vue.$tc(prefix + '.' +  txt)
      }
      if(isSnakeCase) {
        const sTxt = prefix + '.' +  vue.$_.snakeCase(txt)
        //console.log(sTxt)
        return vue.$t(sTxt) === sTxt ? txt : vue.$t(sTxt)
      }
      return vue.$t(prefix + '.' +  txt)
    },


    getURLParameters: (name) => {
      const search = !location.search ? location.hash.substr(2) : location.search
      // eslint-disable-next-line no-useless-escape
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
      const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
      const results = regex.exec(search)
      return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '))
    },

    loadingIntToLoadingOptions: (i) => {
      const options = config.loadingOptions
      const bin = utils.binMap[i]
      const c = bin.split('', bin)
      let o = []
      vue.$_.forEach(c, (item, index) => {
        if (parseInt(item) === 1) {
          o.push(options[index].type)
        }
      })
      return o
    },
    nl2br: (q) => {
      return vue.$_.isString(q) ? q.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2') : q
    },
    br2nl: (q) => {
      return vue.$_.isString(q) ? q.replace(/<br>/g, '\r') : q
    },
    customSort: (items, index, isDesc) => {
      index = index[0]
      isDesc = isDesc[0]
      items.sort((a, b) => {
        if (index === 'created_de') {
          if (!isDesc) {
            return a['created_sort'] > b['created_sort'] ? 1 : -1
          } else {
            return b['created_sort'] > a['created_sort'] ? 1 : -1
          }
        }
        else if (index === 'modified_de') {
          if (!isDesc) {
            return a['modified_sort'] > b['modified_sort'] ? 1 : -1
          } else {
            return b['modified_sort'] > a['modified_sort'] ? 1 : -1
          }
        }

        else {
          if (!isDesc) {
            return a[index] > b[index] ? 1 : -1
          } else {
            return b[index] > a[index] ? 1 : -1
          }
        }
      })
      return items
    },
    setStyle: (styleObject, vuetify) => {
      vuetify.theme.themes.dark.primary = styleObject.primary
      vuetify.theme.themes.dark.secondary = styleObject.secondary
      vuetify.theme.themes.light.primary = styleObject.primary
      vuetify.theme.themes.light.secondary = styleObject.secondary
      vuetify.theme.dark = styleObject.isDark
    },
    getAppInfo: () => {
      let info = {}
      info.buildTime = new Date(process.env.VUE_APP_BUILDTIME).toLocaleString('de', {hour: '2-digit', minute: '2-digit', second: '2-digit', day: '2-digit', month: '2-digit', year: 'numeric'})
      info.type = process.env.VUE_APP_MODE
      info.version =  process.env.VUE_APP_VERSION
      return info
    },
  }

  Vue.prototype.$utils = utils
}

export default utilsPlugin

