let Rules = {}
Rules.install = function (Vue) {
  let $rules = {}

  $rules.required = value => {
    return (!!value || value === 0) || 'Erforderlich.'
  }

  $rules.email = value => {
    if (!value) {
      return true
    }
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || 'Ungültige Email.'
  }

  $rules.minLength4 = value => {
    if (!value) {
      return false
    } else {
      return value.length >= 4 || 'Mindestens 4 Zeichen.'
    }
  }

  $rules.minLength4ButNotRequired = value => {
    if (!value) {
      return true
    } else {
      return value.length >= 4 || 'Mindestens 4 Zeichen.'
    }
  }

  $rules.minLength2 = value => {
    if (!value) {
      return false
    } else {
      return value.length >= 2 || 'Mindestens 2 Zeichen.'
    }
  }

  $rules.length2 = value => {
    if (!value) {
      return false
    } else {
      return value.length === 2 || 'Exakt 2 Zeichen.'
    }
  }

  $rules.alpha = value => {
    if (!value) {
      return false
    } else {
      const pattern = /^[a-zA-Z\-_]*$/
      return pattern.test(value) || 'Ungültige Zeichen (a-zA-Z - _).'
    }
  }

  $rules.alphaNum = value => {
    if (!value) {
      return false
    } else {
      const pattern = /^[a-zA-Z0-9\-_*]*$/
      return pattern.test(value) || 'Ungültige Zeichen (a-zA-Z0-9 - _).'
    }
  }

  $rules.float = value => {
    if (!value) {
      return true
    }
    const pattern = /^[\d.]*$/
    return pattern.test(value) || 'Ungültige Dezimalzahl.'
  }

  $rules.integer = value => {
    if (!value) {
      return true
    } else {
      const pattern = /^[\d]*$/
      return pattern.test(value) || 'Ungültige Zahl.'
    }
  }

  $rules.url = value => {
    if (!value) {
      return true
    } else {
      // eslint-disable-next-line
      const pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
      return pattern.test(value) || 'Ungültige URL.'
    }
  }
  Vue.prototype.$rules = $rules
}

export default Rules
