import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [

    {
      path: '/',
      component: () => import(/* webpackChunkName: "Anfrage" */ './views/Index.vue'),
      children: [
        {
          path: '/',
          component: () => import(/* webpackChunkName: "Anfrage" */ './views/Input.vue')
        },
        {
          path: '/inquiry',
          component: () => import(/* webpackChunkName: "Anfrage" */ './views/Inquiry.vue')
        },

      ]
    }

  ]
})
