import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)



const store = new Vuex.Store({
  plugins: [],
  state: {
    config: {},
    langs: null,
    instance: {},
    addons: [],
    search: {},
    inquiry: {
      pickupDelivery: {}
    },
    isDevelopment: process.env.VUE_APP_MODE === 'development',
    token: '',
  },
  actions: {
    saveSessionDataToLocaleStorage(state) {
      const data = {
        search: state.getters.search,
        addons: state.getters.addons,
        inquiry: state.getters.inquiry
      }
      sessionStorage.setItem('sessionData', JSON.stringify(data))
    },
    getSessionDataFromLocaleStorage(state) {
      if(sessionStorage.getItem('sessionData')) {
        const data = JSON.parse(sessionStorage.getItem('sessionData'))
        if(data.search) {
          state.commit('setSearch', data.search)
        }
        if(data.inquiry) {
          state.commit('setInquiry', data.inquiry)
        }
        if(data.addons) {
          state.commit('setAddons', data.addons)
        }
      }
    },
    clearSessionData(state) {
      sessionStorage.removeItem('sessionData')
      state.commit('setSearch', {})
      state.commit('setInquiry', {pickupDelivery: {}})
      //state.commit('setAddons', [])
    }
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    },
    setInstance (state, instance) {
      state.instance = instance
    },
    setUser (state, user) {
      state.user = user
    },
    setLangs(state, langs) {
      state.langs = langs
    },
    setConfig (state, config) {
      state.config = Object.assign(state.config, config)
    },
    setSearch (state, search) {
      state.search = search
    },
    searchSetLocation (state, props) {
      state.search[props.type] = props.location
    },
    searchSetDistance(state, distanceText) {
      state.search.mapDistance = distanceText
    },
    searchSetZone (state, props) {
      state.search.zone = props.zone
      state.search.mapDistance = props.mapDistance
      state.search.mapDistanceText = props.mapDistanceText
    },
    searchSetLoading (state, props) {
      state.search.loading = props.loading
      state.search.unloading = props.unloading
    },
    searchSetFreight (state, props) {
      state.search.freight = props
    },
    setInquiry (state, inquiry) {
      state.inquiry = inquiry
    },
    setAddress (state, props) {
      if(!state.inquiry) {
        state.inquiry = {addresses: {}}
      }
      if(!props.type) {
        state.inquiry.addresses = props.data
      } else {
        state.inquiry.addresses[props.type] = props.data
      }
    },
    setInquiryValue (state, props) {
      state.inquiry[props.key] = props.value
    },
    setInquiryObject (state, obj) {
      state.inquiry[obj.key] = obj
    },
    setInquiryPickupDelivery (state, props) {
      state.inquiry.pickupDelivery = props
    },
    setAddons(state, addons) {
      state.addons = addons
    },
    setInquiryAddons(state, addons) {
      state.inquiry.addons = addons
    },
    addInquiryAddon(state, addon) {
      if(!state.inquiry.addons) {
        state.inquiry.addons = []
      }
      state.inquiry.addons.push(addon)
    }
   },
  getters: {
    token: state => {
      return state.token
    },
    config: state => {
      return state.config
    },
    instance: state => {
      return state.instance
    },
    idInstance: state => {
      return parseInt(state.instance.id) || 0
    },
    search: state => {
      return state.search || null
    },
    addons: state => {
      return state.addons.length > 0 ? state.addons : null
    },
    inquiry: state => {
      return state.inquiry || null
    },
    langs: state => {
      return state.langs
    },
  },
  modules: {}
})

export default store
