<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <v-card :loading="true">
      <v-card-title >
        Lade Anwendung...
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            Erforderliche Daten werden geladen.
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogLoading',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
  },
  methods: {
  }
}
</script>

