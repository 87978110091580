import Vue from 'vue'
import _ from 'lodash'

const VueLodash = {
  install (Vue) {
    // prevent duplicate instance on SSR
    if (!Vue.prototype._) {
      Object.defineProperty(Vue.prototype, '$_', { value: _ })
    }
  }
}

export default VueLodash

Vue.use(VueLodash)
