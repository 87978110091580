import axios from 'axios'
import qs from 'qs'

let httpPlugin = {}
httpPlugin.install = function (Vue) {
  let $http = {}

  $http.all = axios.all
  $http.spread = axios.spread
  $http.idleInt = 0

  $http.init = (vue) => {
    $http.api = axios.create({
      baseURL: process.env.VUE_APP_PROXY_URL + 'api/',
      timeout: 10000
    })

    $http.etc = axios.create({
      baseURL: process.env.VUE_APP_PROXY_URL + 'etc/',
      timeout: 10000
    })

    $http.ext = axios.create({
      baseURL: process.env.VUE_APP_PROXY_URL + 'ext/',
      timeout: 10000
    })

    $http.api.interceptors.request.use(cf => {
      if (!cf.params) {
        cf.params = {}
      }
      if (qs.stringify(cf.params)) {
        cf.headers['X-Params'] = qs.stringify(cf.params)
      }
      cf.headers['X-Auth-Token'] = sessionStorage.getItem('token') || null
      cf.baseURL+= '?v=' + $http.uniqid(cf.url, true)
      if (cf.url) {
        cf.headers['X-Proxy-Url'] = 'api/' + cf.url
      }
      cf.url = ''
      return cf
    }, err => {

      return Promise.reject(err)
    })

    $http.api.interceptors.response.use(response => {
      return response;
    }, err => {
      if(err.message.indexOf('504') > 0) {
        vue.$root.doAppError(err.message, 1)
      }
      if(err.message.indexOf('500') > 0) {
        vue.$root.doAppError(err.message, 0)
      }
      vue.$root.doNoticeError(err.message)
      return Promise.reject(err);
    });

    $http.etc.interceptors.request.use(cf => {
      if (!cf.params) {
        cf.params = {}
      }
      if (qs.stringify(cf.params)) {
        cf.headers['X-Params'] = qs.stringify(cf.params)
      }
      cf.headers['X-Auth-Token'] = sessionStorage.getItem('token') || null
      cf.baseURL+= '?v=' + $http.uniqid(cf.url, true)
      if (cf.url) {
        cf.headers['X-Proxy-Url'] = 'etc/' + cf.url
      }

      cf.url = ''
      return cf
    }, err => {
      return Promise.reject(err)
    })

    $http.etc.interceptors.response.use(response => {
      return response;
    }, err => {
      if(err.message.indexOf('504') > 0) {
        vue.$root.doAppError(err.message, 1)
      }
      if(err.message.indexOf('500') > 0) {
        vue.$root.doAppError(err.message, 0)
      }
      vue.$root.doNoticeError(err.message)
      return Promise.reject(err);
    });

    $http.ext.interceptors.request.use(cf => {
      if (!cf.params) {
        cf.params = {}
      }
      if (qs.stringify(cf.params)) {
        cf.headers['X-Params'] = qs.stringify(cf.params)
      }
      cf.headers['X-Auth-Token'] = sessionStorage.getItem('token') || null

      if (cf.url) {
        cf.headers['X-Proxy-Url'] = 'ext/' + cf.url
      }

      cf.url = ''
      return cf
    }, err => {
      return Promise.reject(err)
    })

    $http.ext.interceptors.response.use(response => {
      return response;
    }, err => {
      if(err.message.indexOf('504') > 0) {
        vue.$root.doAppError(err.message, 1)
      }
      if(err.message.indexOf('500') > 0) {
        vue.$root.doAppError(err.message, 0)
      }
      vue.$root.doNoticeError(err.message)
      return Promise.reject(err);
    });

    $http.idle = () => {
      let doIdleFunction = () => {
        vue.$http.etc.get('idle')
        .then( () => {
          //
        })
        .catch(() => {
          vue.$root.doAppError('', true)
          sessionStorage.removeItem('token')
          clearInterval(vue.$http)
        })
      }
      vue.$http.idleInt = setInterval(() => {
        doIdleFunction()
      },  50000)
      doIdleFunction()
    }


    $http.filterApiCollection = (collection, forceInt, forceTrim, forceFloat) => {

      function _filter (obj, item, index) {
        if (index !== '_links') {
          // eslint-disable-next-line
          if (parseInt(item) == item && (index.substr(0, 2) === 'id' || forceInt)) {
            if (index.indexOf('zip') === -1) {
              item = parseInt(item)
            }
          }
          // eslint-disable-next-line
          if (parseFloat(item) == item && forceFloat) {
            item = parseFloat(item)
          }
          if (forceTrim && vue.$_.isString(item)) {
            item = vue.$_.trim(item)
          }
          obj[index] = item
        }
      }

      if (collection && collection !== undefined) {
        if (vue.$_.has(collection.data, '_embedded')) {
          let arr = []
          let d
          const e = vue.$_.pickBy(collection.data._embedded, vue.$_.isArray)
          vue.$_.forEach(e, f => {
            d = f
          })
          vue.$_.forEach(d, row => {
            let obj = {}
            vue.$_.forEach(row, (item, index) => {
              _filter(obj, item, index)
            })
            arr.push(obj)
          })
          return arr.length > 0 ? arr : false
        } else if (vue.$_.has(collection, '_embedded')) {
          let arr = []
          let d
          const e = vue.$_.pickBy(collection._embedded, vue.$_.isArray)
          vue.$_.forEach(e, f => {
            d = f
          })
          vue.$_.forEach(d, row => {
            let obj = {}
            vue.$_.forEach(row, (item, index) => {
              _filter(obj, item, index)
            })
            arr.push(obj)
          })
          return arr.length > 0 ? arr : false
        } else if (vue.$_.isObject(collection.data)) {
          let obj = {}
          vue.$_.forEach(collection.data, (item, index) => {
            _filter(obj, item, index)
          })
          return obj
        } else if (vue.$_.isArray(collection.data)) {
          let obj = {}
          vue.$_.forEach(collection.data[0], (item, index) => {
            _filter(obj, item, index)
          })
          return obj
        } else if (vue.$_.isObject(collection)) {
          let obj = {}
          vue.$_.forEach(collection, (item, index) => {
            _filter(obj, item, index)
          })
          return obj
        } else {
          return false
        }
      }
    }

    $http.uniqid = (prefix = "", random = false) => {
      const sec = Date.now() * 1000 + Math.random() * 1000
      const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0")
      return `${prefix}.${id}${random ? `.${Math.trunc(Math.random() * 100000000)}`:""}`
    }
  }

  Vue.prototype.$http = $http
}

export default httpPlugin
