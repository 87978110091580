<template>
  <v-dialog v-model="dialogAppError" persistent max-width="400" overlay-opacity="0.9">
    <v-card>
      <v-card-title class="headline primary">{{ header }}</v-card-title>
      <v-card-text class="mt-4">{{text}}</v-card-text>
      <v-card-actions>
        <v-btn @click="restart" block>Applikation neu starten</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DialogAppError',
    props: {
      dialogAppError: {
        type: Boolean,
        default: false
      },
      isTimeout: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      restart() {
        document.location.reload()
      }
    },
    computed: {
      header() {
        return this.$root.appError.isTimeout ? 'Timeout Error' : 'App Error'
      },
      text() {
        return this.$root.appError.msg ? this.$root.appError.msg :
            (this.$root.appError.isTimeout ? 'Die Applikation war zu lange inaktiv. Bitte starten Sie neu.' : 'Die Applikation hat einen Fehler verursacht. Bitte starten Sie neu.')
      }
    }
  }
</script>

